<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark text-white font-bold w-full fixed md:static"
		>
			<span class="ml-2">Cotización</span>

			<div class="flex items-center justify-center">
				<router-link to="/Cotizaciones"
					><button
						@click="$emit('click')"
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						<svg
							version="1.1"
							id="Capa_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 368.022 368.022"
							style="enable-background: new 0 0 368.022 368.022"
							class="h-6 w-6"
							xml:space="preserve"
						>
							<path
								style="fill: #d7443e"
								d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
							/>
							<polygon
								style="fill: #ffffff"
								points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
							/>
						</svg>
						<span class="underline ml-2">Cancelar cotización</span>
					</button></router-link
				>
			</div>
		</div>
		<!--==============================Campo superiores=====================================================-->
		<div class="md:flex items-center justify-between mt-20 md:mt-4 md:ml-0">
			<div class="text-center w-1/3 ml-5">
				<div class="flex">
					<div class="flex items-center justify-start w-22 mr-1">
						<span class="text-botonT font-bold w-20">Vendedor</span>
					</div>
					<vSelect
						class="w-64 ml-6 border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center focus:outline-none"
						:options="opcionesVendedor"
						v-model="vendedorAsignado"
						@input="vendedor => asignarVendedor(vendedor)"
					></vSelect>
				</div>
				<div class="flex pt-3">
					<div class="flex items-center justify-start w-22">
						<span class="text-botonT font-bold w-16">Teléfono</span>
					</div>
					<input
						class=""
						type="radio"
						name="TelefonoCliente"
						id="TelefonoCliente"
						value="telefonoCliente"
						v-model="ingresoNombreCliente"
						@change="clearData"
					/>
					<div v-if="ingresoNombreCliente === 'telefonoCliente'">
						<vSelect
							class="w-64 ml-3 border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center focus:outline-none"
							:options="numeroCliente"
							v-model="numeroTlf"
							:key="selectRefresh"
							@input="idCliente()"
						></vSelect>
					</div>
					<div v-else>
						<input
							type="text"
							disabled
							class="w-64 ml-3 border border-secundB rounded bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							v-model="numeroTlf"
						/>
					</div>
				</div>

				<div class="flex pt-3">
					<div class="flex items-center justify-start w-22">
						<p class="text-botonT font-bold w-16">Nombre</p>
					</div>
					<input
						class=""
						type="radio"
						name="NombreCliente"
						id="TelefonoCliente"
						value="nombreCliente"
						v-model="ingresoNombreCliente"
						@change="clearData"
					/>
					<div v-if="ingresoNombreCliente === 'nombreCliente'">
						<vSelect
							class="w-64 ml-3 border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center focus:outline-none"
							:options="usuariosArraySelect"
							v-model="nombreCliente"
							:key="selectRefresh"
							@input="idCliente()"
						></vSelect>
					</div>
					<div v-else>
						<input
							type="text"
							class="w-64 ml-3 border border-secundB rounded bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							disabled
							v-model="nombreCliente"
						/>
					</div>
				</div>
			</div>
			<div class="text-center w-1/3">
				<div class="flex ml-5">
					<Campo2 class="" campoTitulo="Tasa del día $">
						<input
							type="text"
							disabled
							class="w-64 mr-3 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							:value="formato(tasadia)"
						/>
					</Campo2>
				</div>
				<div class="flex justify-start">
					<input
						class="mr-3 mt-3"
						type="radio"
						name="DescuentoAumento"
						id="descuento"
						value="Descuento"
						v-model="tipoPorcentaje"
					/>
					<Campo2 class="mt-3" campoTitulo="% Descuento">
						<input
							type="text"
							class="w-64 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							v-model="descuento"
						/>
					</Campo2>
				</div>
				<div class="flex justify-start">
					<input
						class="mr-3 mt-3"
						type="radio"
						name="DescuentoAumento"
						id="aumento"
						value="Aumento"
						v-model="tipoPorcentaje"
					/>
					<Campo2 class="mt-3 ml-3" campoTitulo="% Aumento">
						<input
							type="text"
							class="w-64 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
							v-model="aumento"
						/>
					</Campo2>
				</div>
			</div>
		</div>
		<div class="md:text-center mt-8">
			<div class="md:inline-flex justify-center">
				<div>
					<input
						class="mx-3"
						type="radio"
						name="TipoPdf"
						id="aumento"
						value="Bolivares"
						v-model="tipoPDF"
					/>
					<span> Mostrar monto en Bs al cliente</span>
				</div>
				<div>
					<input
						class="mx-3"
						type="radio"
						name="TipoPdf"
						id="aumento"
						value="Dolares"
						v-model="tipoPDF"
					/>
					<span> Mostrar monto en $ al cliente</span>
				</div>
				<div>
					<input
						class="mx-3"
						type="radio"
						name="TipoPdf"
						id="aumento"
						value="DolaresBolivares"
						v-model="tipoPDF"
					/>
					<span> Mostrar monto en Bs y $ al cliente</span>
				</div>
			</div>
		</div>
		<!--===============================Tabla ingreso de datos==================================================-->
		<div class="flex justify-center mx-10">
			<table id="producto" class="table-auto mt-4">
				<thead>
					<tr>
						<th class="border-2 px-4 py-2">Rubro</th>
						<th class="border-2 px-4 py-2">Cant.</th>
						<th class="border-2 px-4 py-2">Producto</th>
						<th class="border-2 px-4 py-2">Und.</th>
						<th class="border-2 px-4 py-2 w-28">P. Unit. Bs</th>
						<th class="border-2 px-4 py-2 w-30">P. Unit. $</th>
						<th class="border-2 px-4 py-2 w-28">Total Bs</th>
						<th class="border-2 px-4 py-2 w-28">Total $</th>
						<!-- <th class="border-2 px-4 py-2">%</th> -->
						<th class="border-2 px-4 py-2">Comentario</th>
						<th class="border-2 px-4 py-2">Vendedor</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(cotizacion, k) in productosCotizacion" :key="k">
						<td class="border-2 px-4 py-4">
							<vSelect
								class="w-32 bg-white hover:bg-gray-200 text-black items-center focus:outline-none"
								:options="opcionesRubro"
								v-model="cotizacion.Rubro"
								@input="opcionesProducto(cotizacion.Rubro)"
							></vSelect>
						</td>
						<td class="border-2 px-4 py-4">
							<input
								class="w-16 focus:outline-none px-auto"
								type="number"
								step="0.01"
								v-model="cotizacion.Cantidad"
								@keyup="montoCobrarBsUsd(cotizacion)"
							/>
						</td>
						<td class="border-2 px-4 py-4">
							<vSelect
								@change="productoId"
								class="w-64 bg-white hover:bg-gray-200 text-black items-center focus:outline-none"
								:options="nuevoProducto"
								v-model="cotizacion.Producto"
								:key="selectRefresh"
								@input="precioPorproducto(cotizacion.Producto)"
								>{{ llenarOpcionesProductos }}
							</vSelect>
						</td>

						<td class="border-2 px-4 py-4">
							{{ cotizacion.Unidad }}
						</td>

						<td class="border-2 px-4 py-1">
							{{ formato(cotizacion.PrecioUnitarioBs) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizacion.PrecioUnitarioD) }}
						</td>
						<td class="border-2 px-4 py-4 text-center">
							<div
								class="text-center"
								v-if="
									cotizacion.PrecioUnitarioBs != '' && cotizacion.cantidad != ''
								"
							>
								{{ formato(cotizacion.MontoCobrarBs) }}
							</div>
							<div class="text-center" v-else>
								{{ (cotizacion.MontoCobrarBs = 0) }}
							</div>
						</td>
						<td class="border-2 px-4 py-4 text-center">
							<div
								class="text-center"
								v-if="
									cotizacion.PrecioUnitarioD != '' && cotizacion.cantidad != ''
								"
							>
								{{ formato(cotizacion.MontoCobrarD) }}
							</div>
							<div class="text-center" v-else>
								{{ (cotizacion.MontoCobrarD = 0) }}
							</div>
						</td>
						<!-- <td class="border-2 px-4 py-4">
              {{ cotizacion.Descuento }}
            </td> -->
						<td class="border-2">
							<textarea
								class="w-full focus:outline-none px-auto"
								v-model="cotizacion.Comentario"
							/>
						</td>
						<td class="border-2 px-10">
							<input
								class="h-4 w-4"
								type="checkbox"
								id="asignar"
								name="vendedor"
								@keypress.enter="consultarVendedor = !consultarVendedor"
								v-model="consultarVendedor"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="flex justify-center pt-10" v-if="showMessage">
			<p>{{ message }}</p>
		</div>

		<div
			v-if="mostrarAnadirProducto"
			class="flex items-center justify-center w-full my-10"
		>
			<Botons
				@click="addNewRow"
				@keypress.enter="addNewRow"
				ref="prueba12"
				class="underline mr-10 focus:border-azul focus:border-2 focus:rounded-lg"
				botonsText="Añadir producto"
			>
				<anadir class="h-6 w-6 mr-2 fill-current text-botonT" />
			</Botons>
		</div>

		<div
			v-if="notificarVendedor"
			class="flex items-center justify-center w-full my-10"
		>
			<Botons
				@click="notificarVendedorMutation"
				class="underline mr-10"
				botonsText="Notificar Vendedor"
			>
				<anadir class="h-6 w-6 mr-2 fill-current text-botonT" />
			</Botons>
		</div>
		<!--==============================Tabla de cotizacion=====================================================-->
		<div class="flex justify-center mx-10">
			<table id="cotizarr" class="table-auto mt-4">
				<thead>
					<tr>
						<th></th>
						<th class="border-2 px-4 py-2">Cant.</th>
						<th class="border-2 px-4 py-2">Und.</th>
						<th class="border-2 px-4 py-2">Rubro</th>
						<th class="border-2 px-4 py-2">Producto</th>
						<th class="border-2 px-4 py-2">P. Unit. Bs</th>
						<th class="border-2 px-4 py-2">P. Unit.$</th>
						<th class="border-2 px-4 py-2">Total Bs</th>
						<th class="border-2 px-4 py-2">Total $</th>
						<!-- <th class="border-2 px-4 py-2">%</th> -->
						<th class="border-2 px-4 py-2">Comentario</th>
						<th class="border-2 px-4 py-2">Vendedor</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(cotizaciont, k) in productosCotizacionT" :key="k">
						<td scope="row">
							<svg
								@click="deleteRow(k, cotizaciont)"
								class="hover:opacity-100 cursor-pointer fill-current text-red-500 opacity-75"
								width="26"
								height="26"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g id="delete_sweep_24px">
									<path
										id="icon/content/delete_sweep_24px"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
									/>
								</g>
							</svg>
						</td>
						<td class="border-2 px-4 py-4">
							{{ cotizaciont.Cantidad }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ firstLetterUpperCase(cotizaciont.Unidad) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ firstLetterUpperCase(cotizaciont.Rubro) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ firstLetterUpperCase(cotizaciont.Producto) }}
						</td>
						<td class="border-2 px-4 py-1">
							{{ formato(cotizaciont.PrecioUnitarioBs) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizaciont.PrecioUnitarioD) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizaciont.MontoCobrarBs) }}
						</td>
						<td class="border-2 px-4 py-4">
							{{ formato(cotizaciont.MontoCobrarD) }}
						</td>
						<!-- <td class="border-2 px-4 py-4">
              {{ formato(cotizaciont.Descuento) }}
            </td> -->
						<td class="border-2 px-4 py-4">
							<textarea
								class="w-full focus:outline-none px-auto"
								v-model="cotizaciont.Comentario"
							/>
						</td>
						<td class="border-2 px-10">
							<input
								v-model="cotizaciont.consultarVendedor"
								class="h-4 w-4"
								type="checkbox"
								id="asignar"
								name="vendedor"
							/>
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Exento</td>
						<td class="text-center border-2 px-4 py-4">
							{{
								formato(
									parseFloat(subtotalbs) -
										parseFloat(ivabs) / ($store.state.ivaGeneral / 100)
								)
							}}
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{
								formato(
									parseFloat(subtotalusd) -
										parseFloat(ivad) / ($store.state.ivaGeneral / 100)
								)
							}}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Subtotal</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(parseFloat(ivabs / ($store.state.ivaGeneral / 100))) }}
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(parseFloat(ivad / ($store.state.ivaGeneral / 100))) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">
							{{ "% de " + tipoPorcentaje }}
						</td>
						<td
							colspan="2"
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							<input
								class="text-center px-4 py-4"
								type="text"
								name=""
								id=""
								v-model="aumento"
							/>
						</td>
						<td
							colspan="2"
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							<input
								class="text-center px-4 py-4"
								type="text"
								name=""
								id=""
								v-model="descuento"
							/>
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Base Imponible sin %</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(subtotalbs) }}
						</td>
						<td class="text-center border-2 px-4 py-4">
							{{ formato(subtotalusd) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Base Imponible</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalbs / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalusd / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalbs * (1 - parseFloat(descuento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(subtotalusd * (1 - parseFloat(descuento) / 100)) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">IVA</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivabs / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivad / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivabs * (1 - parseFloat(descuento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(this.ivad * (1 - parseFloat(descuento) / 100)) }}
						</td>
					</tr>
					<tr v-if="show">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center border-2 px-4 py-4">Total</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalbs / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Aumento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalusd / (1 - parseFloat(aumento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalbs * (1 - parseFloat(descuento) / 100)) }}
						</td>
						<td
							v-if="tipoPorcentaje === 'Descuento'"
							class="text-center border-2 px-4 py-4"
						>
							{{ formato(totalusd * (1 - parseFloat(descuento) / 100)) }}
						</td>
					</tr>
					<!-- <tr v-if="show">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center border-2 px-4 py-4">Utilidad Aproximada</td>
            <td
              v-if="tipoPorcentaje === 'Aumento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalbs * (1 + parseFloat(aumento) / 100) -
                    utilidadAproximadaBs
                )
              }}
            </td>
            <td
              v-if="tipoPorcentaje === 'Aumento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalusd * (1 + parseFloat(aumento) / 100) -
                    utilidadAproximadaUSD
                )
              }}
            </td>
            <td
              v-if="tipoPorcentaje === 'Descuento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalbs * (1 - parseFloat(descuento) / 100) -
                    utilidadAproximadaBs
                )
              }}
            </td>
            <td
              v-if="tipoPorcentaje === 'Descuento'"
              class="text-center border-2 px-4 py-4"
            >
              {{
                formato(
                  subtotalusd * (1 - parseFloat(descuento) / 100) -
                    utilidadAproximadaUSD
                )
              }}
            </td>
          </tr> -->
				</tbody>
			</table>
		</div>
		<div
			v-if="productosCotizacionT != ''"
			class="flex items-center justify-center w-full my-10"
		>
			<Botonp
				class="underline"
				botonpText="Calcular Total"
				@click="
					calcularTotalbs();
					calcularTotalusd();
					verificarVendedorCliente();
					utilidadAproximada();
				"
			/><!-- cotizacionId(); -->
		</div>
		<div v-if="botones" class="flex items-center justify-center mb-12">
			<Botonp
				botonpText="Guardar cotización"
				class="mx-10"
				@click="guardarCotizacion()"
			/>
			<Botons
				v-if="mostrarPDF"
				botonsText="Imprimir PDF"
				class="mx-10"
				@click="generarPDF()"
			/>
		</div>
	</div>
</template>
<script>
import Botons from "@/components/botonSecundario";
import Botonp from "@/components/botonPrimario";
import anadir from "@/components/añadir";
import Campo2 from "@/components/campoCotizar";
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import { formato, firstLetterUpperCase } from "../functions.js";
import pdfMixin from "@/mixins/pdfMixin.js";
import cotizarMixin from "@/mixins/cotizarMixin.js";
import rubroTipoCambio from "@/mixins/rubroTipoCambio.js";

export default {
	name: "Cotizar",
	components: {
		Botons,
		Botonp,
		anadir,
		vSelect,
		Campo2
	},
	data: function() {
		return {
			nombreCliente: "",
			ingresoNombreCliente: "nombreCliente",
			notificarVendedor: false,
			mostrarAnadirProducto: true,
			nuevoProducto: [],
			tipoPDF: "Dolares",
			consultarVendedor: false,
			mostrarPDF: false,
			tipoPorcentaje: "Aumento",
			showMessage: false,
			message: "",
			aumento: "0",
			selectRefresh: true,
			descuento: "0",
			llenarOpcionesProductos: [],
			productoObjeto: [],
			botones: false,
			tasadia: "",
			mostrarDolar: false,
			show: false,
			vendedoress: [],
			usuarios: [],
			usuariosArraySelect: [],
			numeroCliente: [],
			numeroTlf: "",
			vendedorAsignado: "",
			vendedorId: "",
			clienteId: "",
			productoId: "",
			totalbs: 0,
			subtotalbs: 0,
			totalusd: 0,
			subtotalusd: 0,
			ivabs: 0,
			ivad: 0,
			rubroCompletado: [],
			productosCotizacionT: [],
			productosCotizacion: [
				{
					Cantidad: "",
					Unidad: "",
					Rubro: "",
					Producto: "",
					PrecioUnitarioBs: "",
					PrecioUnitarioD: "",
					MontoCobrarBs: "",
					MontoCobrarD: "",
					Comentario: "NA",
					Vendedor: "",
					Descuento: "",
					consultarVendedor: "",
					ivabs: "",
					ivad: "",
					tasa_proveedores: {}
				}
			],
			ultimoRubro: "2",
			numeroCotizacion: {},
			user_Receptor: "",
			notificacionVendedorWS: false,
			cotizacionIdBroadcasting: 0,
			utilidadAproximadaUSD: 0,
			utilidadAproximadaBs: 0,
			rubrosTotal: [],
			CotizacionId: []
		};
	},
	mixins: [pdfMixin, cotizarMixin, rubroTipoCambio],
	/////////////////////////mounted////////////////////////////////////////////
	async created() {
		await this.rubroTotalQuery();
		await this.tipoCambioQuery();
		await this.usuariosQuery();
		await this.listaVendedoresQuery();
		await this.getNumeroCotizacion();
	},

	////////////////////////////metodos////////////////////////////////
	methods: {
		formato,
		firstLetterUpperCase,
		clearData() {
			this.nombreCliente = "";
			this.numeroTlf = "";
		},
		asignarVendedor(nuevoVendedor) {
			this.productosCotizacion[0].Vendedor = nuevoVendedor;
		},

		async getNumeroCotizacion() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/queries/numeroCotizacion.gql"),
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.numeroCotizacion = data.data.asignarCotizacionId.cotizacion_id;
					this.CotizacionId.push({ cotizacion_id: this.numeroCotizacion });
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},

		verificarVendedorCliente() {
			if (this.vendedorAsignado != "" && this.numeroTlf != "") {
				this.idVendedor();
				this.idCliente();
			} else {
				this.show = false;
				this.botones = false;
				alert("Vendedor o cliente no asignado");
			}
		},

		idVendedor() {
			for (let j = 0; j < this.vendedoress.length; j++) {
				if (
					this.vendedorAsignado.substring(
						0,
						this.vendedorAsignado.length - 5
					) === this.vendedoress[j].name
				) {
					this.vendedorId = this.vendedoress[j].vendedor_id;
					this.user_Receptor = this.vendedoress[j].user_id;
					break;
				}
			}
		},

		idCliente() {
			let nombreTelefonoCliente = [];
			if (this.ingresoNombreCliente === "telefonoCliente") {
				nombreTelefonoCliente = this.usuarios.find(
					x => x.numero_telefono_movil == this.numeroTlf
				);
				this.nombreCliente = nombreTelefonoCliente.name;
			} else {
				nombreTelefonoCliente = this.usuarios.find(
					x => x.name == this.nombreCliente
				);
				this.numeroTlf = nombreTelefonoCliente.numero_telefono_movil;
			}
			this.clienteId = nombreTelefonoCliente.id;
		},

		deleteRow(index, cotizacion) {
			const idx = this.productosCotizacionT.indexOf(cotizacion);
			if (idx > -1) {
				this.productosCotizacionT.splice(idx, 1);
			}
		},
		idProducto() {
			for (let j in this.productoObjeto) {
				if (
					this.productosCotizacion[0].Productos ===
					this.productoObjeto[j].producto
				) {
					this.productoId = this.productoObjeto[j].id;
					break;
				}
			}
		},
		llenarObjetoCotizacion() {
			let data = [];
			let porcentaje = 0;

			if (this.tipoPorcentaje === "Aumento") {
				porcentaje = parseFloat(this.aumento);
			} else {
				porcentaje = -1 * parseFloat(this.descuento);
			}

			for (let i = 0; i < this.productosCotizacionT.length; i++) {
				if (this.productosCotizacionT[i].consultarVendedor) {
					this.notificacionVendedorWS = true;
				}
				data.push({
					vendedor_id: parseInt(this.vendedorId),
					user_id: parseInt(this.clienteId),
					compras_id: 1,
					cantidad: parseFloat(this.productosCotizacionT[i].Cantidad),
					precios_id: parseInt(this.productosCotizacionT[i].Idprecio),
					total_usd: parseFloat(this.productosCotizacionT[i].MontoCobrarD),
					estado: 0,
					comentario: this.productosCotizacionT[i].Comentario,
					comprado: 0,
					cotizacion: 0,
					cotizacion_id: parseInt(this.numeroCotizacion),
					porcentaje: porcentaje,
					notificar: this.productosCotizacionT[i].consultarVendedor,
					tasa_proveedores: parseFloat(this.productosCotizacionT[i].tasa_proveedores)
				});
			}
			//Cotizacion ID para el broadcasting
			this.cotizacionIdBroadcasting = data[0].cotizacion_id;

			return data;
		},

		async guardarCotizacion() {
			this.$store.state.isLoading = true;
			let dataCotizacion = [];
			dataCotizacion = this.llenarObjetoCotizacion();
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/montarPedido.gql"),
					variables: {
						data: dataCotizacion
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
					alert("Cotizacion guardada con exito");
					this.mostrarPDF = true;
					if (this.notificacionVendedorWS) {
						this.notificacionVendedorWS = false;
						//Realizar metodo post con axios para notificar vendedor por WS.
						this.notificarVendedorBroadcasting();
					}
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					this.message = error;
					this.showMessage = true;
					console.error(JSON.parse(JSON.stringify(error)));
				});
		}
	}
};
</script>
